import dashboardIcon from "assets/icons/dashboard.svg";
import reportIcon from "assets/icons/reports.svg";
import projectIcon from "assets/icons/projects.svg";
import userManageIcon from "assets/icons/usersmanage.svg";
import aboutIcon from "assets/icons/about.svg";
import settingsIcon from "assets/icons/settings.svg";
import activityIcon from "assets/icons/activities.svg";
import attendanceIcon from "assets/icons/attendance.svg";
import termsIcon from "assets/icons/terms.svg";
// active icons
import dashboardActiveIcon from "assets/icons/dashboardActiveIcon.svg";
import reportActiveIcon from "assets/icons/reportActiveIcon.svg";
import projectActiveIcon from "assets/icons/projectActiveIcon.svg";
import userManageActiveIcon from "assets/icons/userActiveIcon.svg";
import aboutActiveIcon from "assets/icons/aboutActiveIcon.svg";
import settingsActiveIcon from "assets/icons/settingsActiveIcon.svg";
import activityActiveIcon from "assets/icons/activityActiveIcon.svg";
import attendanceActiveIcon from "assets/icons/attendanceActiveIcon.svg";
import termsActiveIcon from "assets/icons/termsActiveIcon.svg";

export const menuItems = [
  {
    module: "Dashboard",
    icon: dashboardIcon,
    activeIcon: dashboardActiveIcon,
    link: "/dashboard",
    value: 1,
  },
  {
    module: "Projects",
    icon: projectIcon,
    activeIcon: projectActiveIcon,
    link: "/projects",
    value: 1,
  },
  {
    module: "User Management",
    icon: userManageIcon,
    activeIcon: userManageActiveIcon,
    link: "/users",
    value: 1,
  },
  {
    module: "Activities",
    icon: activityIcon,
    activeIcon: activityActiveIcon,
    link: "/activities",
    value: 1,
  },
  {
    module: "Reports",
    icon: reportIcon,
    activeIcon: reportActiveIcon,
    link: "/reports",
    value: 1,
  },

  {
    module: "Attendance Report",
    icon: attendanceIcon,
    activeIcon: attendanceActiveIcon,
    link: "/attendanceReports",
    value: 1,
  },
  {
    module: "About",
    icon: aboutIcon,
    activeIcon: aboutActiveIcon,
    link: "/about",
    value: 1,
  },
  {
    module: "Terms & Policies",
    icon: termsIcon,
    activeIcon: termsActiveIcon,
    link: "/termsPolicies",
    value: 1,
  },

  {
    module: "Settings",
    icon: settingsIcon,
    activeIcon: settingsActiveIcon,
    link: "/settings",
    value: 1,
  },
];
export const menuItemsCosting = [
  {
    module: "Projects",
    icon: projectIcon,
    activeIcon: projectActiveIcon,
    link: "/projects",
    value: 1,
  },
  {
    module: "User Management",
    icon: userManageIcon,
    activeIcon: userManageActiveIcon,
    link: "/users",
    value: 1,
  },
  {
    module: "Reports",
    icon: reportIcon,
    activeIcon: reportActiveIcon,
    link: "/reports",
    value: 1,
  },

  {
    module: "Attendance Report",
    icon: attendanceIcon,
    activeIcon: attendanceActiveIcon,
    link: "/attendanceReports",
    value: 1,
  },
  {
    module: "About",
    icon: aboutIcon,
    activeIcon: aboutActiveIcon,
    link: "/about",
    value: 1,
  },
  {
    module: "Terms & Policies",
    icon: termsIcon,
    activeIcon: termsActiveIcon,
    link: "/termsPolicies",
    value: 1,
  },

  {
    module: "Settings",
    icon: settingsIcon,
    activeIcon: settingsActiveIcon,
    link: "/settings",
    value: 1,
  },
];

export const UserHeaders = [
  { label: "S.No" },
  { label: "User Name", isSort: true, key: "employee_name" },
  { label: "Employee ID", isSort: true, key: "employee_id" },
  { label: "Trade", isSort: true, key: "trade" },
  { label: "Role", isSort: true, key: "role" },
  { label: "Assigned Project", isSort: true, key: "style" },
  { label: "Status", isSort: true, key: "style" },
];
export const projectActiveHeaders = [
  { label: "S.No" },
  { label: "Project Name", isSort: true, key: "project_name" },
  { label: "Address", isSort: false, key: "address" },
  { label: "Region", isSort: true, key: "region" },
  { label: "No.of Wing", isSort: true, key: "wings_count" },
  // { label: "No.of Floors", isSort: false, key: "floors" },
  { label: "Status", isSort: true, key: "style" },
];
export const projectInActiveHeaders = [
  { label: "S.No" },
  { label: "Project Name", isSort: true, key: "project_name" },
  { label: "Address", isSort: false, key: "address" },
  { label: "Region", isSort: true, key: "region" },
  { label: "No.of Wing", isSort: true, key: "wings_count" },
  // { label: "No.of Floors", isSort: false, key: "floors" },
  { label: "Status", isSort: true, key: "style" },
];
export const ActivityHeaders = [
  { label: "S.No" },
  { label: "Trade", isSort: true, key: "trade_name" },
  { label: "Activity Name", isSort: true, key: "activity_master_name" },
  { label: "Sub Activity", isSort: false, key: "region" },
  { label: "UOM", isSort: false, key: "unit_measurement" },
];
export const reportHeaders = [
  { label: "S.No" },
  { label: "Date", isSort: true, key: "activity_date" },
  { label: "Incharge Name", isSort: true, key: "employee_name" },
  { label: "Role", isSort: false, key: "role" },
  { label: "Project Name", isSort: true, key: "project_name" },
  { label: "Trade", isSort: true, key: "activity_trade_name" },
  { label: "Wing", isSort: true, key: "activity_wings_name" },
  { label: "Floor Number", isSort: false, key: "activity_floor_name" },
  { label: "Unit Number", isSort: false, key: "unit_number" },
  { label: "Area Of Work", isSort: false, key: "activity_area_name" },
  { label: "Activity", isSort: true, key: "activity_name" },
  { label: "Sub Activity", isSort: true, key: "sub_activity_name" },
  { label: "UOM", isSort: true, key: "unit_measurement" },
  { label: "Quantity", isSort: true, key: "quantity" },
  { label: "Accepted Quantity", isSort: true, key: "accepted_quantity" },
  { label: "No.of hours", isSort: true, key: "no_of_hours" },
  { label: "Remarks", isSort: true, key: "remarks" },
];

export const dashboardHeaders = [
  { label: "S.No" },
  { label: "Date", isSort: true },
  { label: "Incharge Name", isSort: true, key: "orderReference" },
  { label: "Project Name", isSort: true, key: "customerName" },
  { label: "Place of Work", isSort: true, key: "season" },
  { label: "Trade", isSort: true },
  { label: "Activities", isSort: true },
  { label: "Sub Activity", isSort: true },
  { label: "Quantity", isSort: true },
  { label: "UOM", isSort: true },
  { label: "Remarks", isSort: true },
];
export const attendanceHeaders = [
  { label: "S.No" },
  { label: "Date", isSort: true, key: "punch_date" },
  { label: "Employee ID", isSort: true, key: "employee_id" },
  { label: "Name", isSort: true, key: "employee_name" },
  { label: "Role" },
  { label: "Project", isSort: true, key: "project_name" },
  { label: "Trade", isSort: true, key: "employee_trade_name" },
  { label: "In Time", isSort: true, key: "punch_in_time" },
  { label: "Out Time", isSort: true, key: "punch_out_time" },
];
