import React from "react";
import moment from "moment";
import { Toast } from "service/toast";
import axios from "axios";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

export const history = require("history").createBrowserHistory({});

export const dateFormatter = (date, format = "DD/MM/YYYY") => {
  if (!date) return "";

  const finalDate = moment(date).format(format);
  return finalDate === "Invalid date" ? "" : finalDate;
};

export const permissionAccess = () => {
  const roleAccess = JSON.parse(sessionStorage.getItem("userDetails"));
  return roleAccess?.role;
};

// split filename from url
export const splitFileName = (urlData) => {
  if (urlData) {
    const filePath = urlData;
    const fileName = filePath.split("/").pop();
    return fileName;
  } else {
    return "";
  }
};

//  split image name from url
export const splitImageName = (url) => {
  if (url) {
    // Extract filename and extension using regex
    const matches = url.match(/\/([^/]+\.(?:jpg|png|jpeg|heic))/);

    // If a match is found, get the first capturing group
    const filenameWithExtension = matches ? matches[1] : "";

    return filenameWithExtension;
  }
};

// download csv
export const downloadCSV = (csvData, fileName) => {
  // Create a Blob from the CSV string
  const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });

  // Use FileSaver to save the Blob as a file
  saveAs(blob, fileName);
};

// download xlsx
export const downloadXlsx = (buffer, fileName) => {
  const uint8Array = new Uint8Array(buffer);
  // Parse the array buffer to a workbook
  const workbook = XLSX.read(uint8Array, { type: "array" });
  // Create a Blob from the workbook
  const workbookOut = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const blob = new Blob([workbookOut], { type: "application/octet-stream" });

  // Use FileSaver to save the Blob as a file
  saveAs(blob, fileName);
};

// Function to generate the filename
export const generateFilename = (fileName = "report", fileExtension) => {
  const now = new Date();
  const padToTwoDigits = (num) => num.toString().padStart(2, "0");

  const date = padToTwoDigits(now.getDate());
  const month = padToTwoDigits(now.getMonth() + 1); // Months are zero-indexed
  const year = now.getFullYear();
  const hours = padToTwoDigits(now.getHours());
  const minutes = padToTwoDigits(now.getMinutes());

  return `${date}-${month}-${year}_${hours}-${minutes}-${fileName}.${fileExtension}`;
};

// sample file download
export const sampleFileDownload = (fileUrlName, fileName) => {
  // Construct the file URL using process.env.PUBLIC_URL
  const fileUrl = `${process.env.PUBLIC_URL}/${fileUrlName}`;

  // Create an anchor element
  const link = document.createElement("a");
  link.href = fileUrl;
  link.download = fileName;

  // Append the anchor to the document body and trigger a click event
  document.body.appendChild(link);
  link.click();

  // Remove the anchor from the document body
  document.body.removeChild(link);
};

export const getFormErrorMessage = (errors, fieldArrayError, name) => {
  if (!name || !errors) return null;

  if (!fieldArrayError && name) {
    return errors[name];
  }

  return (
    !!errors[fieldArrayError.arrayName] &&
    errors[fieldArrayError.arrayName][fieldArrayError.index] &&
    typeof errors[fieldArrayError.arrayName][fieldArrayError.index][
      fieldArrayError.fieldName
    ] !== "undefined" &&
    errors[fieldArrayError.arrayName][fieldArrayError.index][
      fieldArrayError.fieldName
    ]
  );
};

export const urlCombiner = (url) => {
  const protocol = ["h", "t", "t", "p"].join("");
  return `${protocol}://${url}`;
};

export const defaultValidator = (val, defaultValue) => {
  return val || defaultValue;
};

export const ToastError = (err) => {
  return Toast({ type: "error", message: err.Error || err.message || "Error" });
};

// add Query
export const addQuery = (dataObject, apiObject) => {
  if (!dataObject) {
    return "";
  }
  const keys = [
    "page",
    "search",
    "size",
    "fileName",
    "dateModified",
    "url_id",
    "status",
    "startDate",
    "endDate",
    "unitId",
    "lineId",
    "mainList",
    "filterStatus",
    "unOrderedList",
    "type",
    "detailsId",
    "reqId",
    "limit",
    "color",
    "currentDate",
    "role",
    "region",
    "trade",
    "wings",
    "projects",
    "start_date",
    "end_date",
    "trade_id",
    "date",
    "project_id",
    "code",
    "sort_by",
    "order_by",
    "isExport",
    "slug",
  ];

  keys.forEach((key) => {
    if (dataObject.hasOwnProperty(key) && typeof dataObject[key] != "object") {
      if (apiObject.query.hasOwnProperty(key)) {
        apiObject.addQuery = { key, payload: dataObject[key] };
      }
    } else {
      dataObject[key] &&
        Object.keys(dataObject[key]).forEach((keyName) => {
          if (apiObject.query.hasOwnProperty(keyName)) {
            apiObject.addQuery = {
              key: keyName,
              payload: dataObject[key][keyName],
            };
          }
        });
    }
  });
};

// generate Query
export const generateQuery = (query) => {
  let url = "";
  if (query.hasOwnProperty("url_id")) {
    url = `/${query?.url_id}`;
  }

  return (
    url +
    Object.keys(query).reduce((accumulator, key, index) => {
      if (
        query[key] === "" ||
        query[key] == null ||
        key === "url_id" ||
        (query[key] !== null && query[key].toString().trim() === "")
      ) {
        return accumulator;
      } else {
        return accumulator + `${index !== 0 ? "&" : "?"}${key}=${query[key]}`;
      }
    }, "")
  );
};

export const getToken = () => {
  if (typeof window !== "undefined") {
    return sessionStorage.getItem("token") || localStorage.getItem("token");
  }
};

export const ternaryCondition = (val1, val2 = "", defaultValue = "") => {
  return val1 ? val2 : defaultValue;
};

//get s.no
export const getSNo = (index, page = 1) => {
  return parseInt((page - 1) * 10) + index + 1;
};

export const getIpAddress = async () => {
  const data = await axios({ method: "get", url: "https://api.ipify.org" });
  let ip = data?.data;
  localStorage.setItem("ip", ip);
};

export const getIp = () => {
  return defaultValidator(localStorage.getItem("ip"), "");
};

export const goBack = () => {
  history.goBack();
};

export const getRoleId = () => {
  let user = JSON.parse(localStorage.getItem("userDetails"));
  return user.role;
};

export const showHyphen = (data) => (!data ? "-" : data);

export const isSelected = (selected, id) => selected?.some((i) => i?.id === id);
// merge the employee code and id
export const mergeCodenId = (empCode, empId) => `${empCode}-${empId}`;

export const Roles = [
  {
    label: "Admin",
    value: 8,
  },
  {
    label: "Technician",
    value: 7,
  },
  {
    label: "Foreman",
    value: 6,
  },
  {
    label: "Supervisor",
    value: 5,
  },
  {
    label: "Project Head",
    value: 3,
  },
  {
    label: "Cost Auditor",
    value: 2,
  },
  {
    label: "Engineer",
    value: 4,
  },
  {
    label: "Super Admin",
    value: 1,
  },
];

export const Grades = [
  {
    label: "A",
    value: 1,
  },
  {
    label: "AA",
    value: 2,
  },
  {
    label: "AAA",
    value: 3,
  },
];

// department
export const Trades = [
  {
    label: "Tiles",
    value: 1,
  },
  {
    label: "POP",
    value: 2,
  },
  {
    label: "Granites",
    value: 3,
  },
  {
    label: "Aluminium",
    value: 4,
  },
  {
    label: "Aluminium",
    value: 5,
  },
];
export const Regions = [
  {
    label: "Kochi",
    value: "Kochi",
  },
  {
    label: "Kochi",
    value: "Kochi",
  },
  {
    label: "Chennai",
    value: "Chennai",
  },
  {
    label: "Mumbai",
    value: "Mumbai",
  },
  {
    label: "Hyderabad",
    value: "Hyderabad",
  },
  {
    label: "Pune",
    value: "Pune",
  },
];

export const getRoleLabelByValue = (value) => {
  const role = Roles.find((role) => role.value === value);
  return role ? role.label : null;
};

export const getGradeLabelByValue = (value) => {
  const grade = Grades.find((data) => data.value === value);
  return grade ? grade.label : null;
};

export const getTradeLabelByValue = (value) => {
  const trade = Trades.find((data) => data.value === value);
  return trade ? trade.label : null;
};
export const getRegionLabelByValue = (value) => {
  const region = Regions.find((data) => data.value === value);
  return region ? region.label : null;
};

// split the empcode and name
export const splitAndFormat = (input) => {
  if (input) {
    let parts = input?.split("-");
    let textPart = parts[0]?.charAt(0) + parts[0]?.slice(1).toUpperCase();
    let numberPart = parts[1];
    return [textPart, numberPart];
  } else {
    return ["", ""];
  }
};

export const projectNamesComma = (data, id) => {
  if (!data) return <span>-</span>;

  const firstTwoProjects = data.slice(0, 2);
  const remainingProjectsCount = data.length - 2;

  const firstTwoProjectsString = firstTwoProjects
    .map((d) => d.project_name)
    .join(", ");

  if (remainingProjectsCount > 0) {
    const handleClick = () => {
      history.push(`/users/view?userId=${id}`);
    };

    return (
      <span onClick={handleClick}>
        {firstTwoProjectsString}
        <span className="remaining__icon">+ {remainingProjectsCount}</span>
      </span>
    );
  } else {
    return <span>{firstTwoProjectsString}</span>;
  }
};

// subActivity
export const subActivityNamesComma = (data, id, subData) => {
  if (!data || !Array.isArray(data)) return <span>-</span>;
  const filteredData = data.filter(
    (item) => item !== null && item !== undefined
  );

  const firstTwoProjects = filteredData.slice(0, 2);
  const remainingProjectsCount = filteredData.length - 2;

  const firstTwoProjectsString = firstTwoProjects
    .map(
      (d) =>
        d &&
        (subData === "activity-name"
          ? d?.sub_activity_name
          : d?.unit_measurement)
    )
    .join(", ");

  if (remainingProjectsCount > 0) {
    const handleClick = () => {
      history.push(`/activities/view/${id}`);
    };

    return (
      <span onClick={handleClick}>
        {firstTwoProjectsString}
        <span className="remaining__icon">+{remainingProjectsCount}</span>
      </span>
    );
  } else {
    return <span>{firstTwoProjectsString}</span>;
  }
};

export const allProjectNamesComma = (data) => {
  return data
    ?.map((d, index, array) => {
      if (index === array.length - 1) {
        return d?.project_name;
      } else {
        return `${d?.project_name}, `;
      }
    })
    .join("");
};

// api res no data found msg
export const NO_DATA = "No data found!";

// check object length
export const isEmpty = (obj) => Object.keys(obj).length;

// scroll to top
export const scrollToTop = () => window.scrollTo(0, 0);

//Show entry
export const generateOptions = (total, step = 10, maxOptions = 10) => {
  const options = [step];
  if (total > step) {
    for (let i = 2; i <= Math.min(Math.ceil(total / step), maxOptions); i++) {
      options.push(i * step);
    }
  }
  return options;
};
