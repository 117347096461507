import { combineReducers } from "@reduxjs/toolkit";
import userMgmtSlice from "./userMgmtSlice";
import aboutAndTermsSlice from "./aboutAndTermsSlice";
import activitySlice from "./activitySlice";
import projectSlice from "./projectSlice";
import reportsSlice from "./reportsSlice";
import attendanceReportSlice from "./attendanceReportSlice";
import dashboardSlice from "./dashboardSlice";
import loginSlice from "./loginSlice";
import loaderSlice from "./loader";
import columnsSlice from "./columnsSlice";

const appReducer = combineReducers({
  auth: loginSlice,
  user: userMgmtSlice,
  activity: activitySlice,
  project: projectSlice,
  reports: reportsSlice,
  aboutandTerms: aboutAndTermsSlice,
  attendance: attendanceReportSlice,
  dashboard: dashboardSlice,
  loader: loaderSlice,
  columns: columnsSlice,
});

const rootReducer = (state, action) => {
  if (action.type === "auth/logout") {
    state = undefined; // Clear all state
  }
  return appReducer(state, action);
};

export default rootReducer;
