import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedColumns: [],
};

const columnsSlice = createSlice({
  name: "columns",
  initialState,
  reducers: {
    setSelectedColumns(state, action) {
      state.selectedColumns = action.payload;
    },
  },
});

export const { setSelectedColumns } = columnsSlice.actions;
export default columnsSlice.reducer;
