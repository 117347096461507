import { createSlice } from "@reduxjs/toolkit";
import { api } from "service/api";
import { Toast } from "service/toast";
import { AboutApi } from "service/apiVariables";

export const aboutAndTermsSlice = createSlice({
  name: "aboutAndTerms",
  initialState: {
    aboutData: "",
    termsData: "",
    policiesData: "",
    isLoading: true,
  },
  reducers: {
    saveAboutData: (state, action) => {
      state.aboutData = action.payload;
      state.isLoading = false;
    },
    saveTermsData: (state, action) => {
      state.termsData = action.payload;
      state.isLoading = false;
    },
    savePolicyData: (state, action) => {
      state.policiesData = action.payload;
      state.isLoading = false;
    },
  },
});

export const aboutAct = (body) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api({ ...AboutApi.getAboutApi, body })
      .then(({ data }) => {
        dispatch(saveAboutData(data));
      })
      .catch(({ message }) => reject(Toast({ type: "error", message })));
  });
};
export const termsAct = (body) => (dispatch) => {
  return new Promise((reject) => {
    api({ ...AboutApi.getTermsApi, body })
      .then(({ data }) => {
        dispatch(saveTermsData(data));
      })
      .catch(({ message }) => reject(Toast({ type: "error", message })));
  });
};
export const policiesAct = (body) => (dispatch) => {
  return new Promise((reject) => {
    api({ ...AboutApi.getPoliciesApi, body })
      .then(({ data }) => {
        dispatch(savePolicyData(data));
      })
      .catch(({ message }) => reject(Toast({ type: "error", message })));
  });
};

export const { saveAboutData, saveTermsData, savePolicyData } =
  aboutAndTermsSlice.actions;

export default aboutAndTermsSlice.reducer;
