import { createSlice } from "@reduxjs/toolkit";
import { addQuery } from "helpers/utis";
import { api } from "service/api";
import { ProjectApi } from "service/apiVariables";
import { Toast } from "service/toast";
import { setLoader } from "./loader";

export const projectSlice = createSlice({
  name: "project",
  initialState: {
    projects: [],
    inActiveProjects: [],
    projectDetails: "",
    isLoading: true,
  },
  reducers: {
    saveProjects: (state, action) => {
      state.projects = action.payload;
      state.isLoading = false;
    },
    saveInActiveProjects: (state, action) => {
      state.inActiveProjects = action.payload;
      state.isLoading = false;
    },
    saveProjectDetail: (state, action) => {
      state.projectDetails = action.payload;
      state.isLoading = false;
    },
    saveUserData: (state, action) => {
      state.userData = action.payload;
    },
  },
});

// get all projs
export const getAllProjectAct = (query) => (dispatch) => {
  addQuery(query, ProjectApi.getAllProjectApi);
  return new Promise((reject) => {
    api({ ...ProjectApi.getAllProjectApi })
      .then(({ data }) => {
        dispatch(saveProjects(data));
        dispatch(setLoader(false));
      })
      .catch(({ message }) => {
        dispatch(setLoader(false));
        reject(Toast({ type: "error", message }));
      });
  });
};

//Inactive Projects
export const getInactiveProjectAct = (query) => (dispatch) => {
  addQuery(query, ProjectApi.getInactiveProjectApi);
  return new Promise((reject) => {
    api({ ...ProjectApi.getInactiveProjectApi })
      .then(({ data }) => {
        dispatch(saveInActiveProjects(data));
        dispatch(setLoader(false));
      })
      .catch(({ message }) => {
        dispatch(setLoader(false));
        reject(Toast({ type: "error", message }));
      });
  });
};

// get individual project
export const getSingleProjectAct = (id, body) => (dispatch) => {
  ProjectApi.getSingleProjectApi.project_id = id;
  addQuery(body, ProjectApi.getSingleProjectApi);
  return new Promise((resolve, reject) => {
    api({ ...ProjectApi.getSingleProjectApi })
      .then((data) => {
        dispatch(saveProjectDetail(data));
        resolve(data);
      })
      .catch(({ message }) => reject(Toast({ type: "error", message })));
  });
};

// get wings / floors/ areaofwrk
export const getWingsProjectAct = (type, body) => () => {
  ProjectApi.getWingsApi.type = type;
  addQuery(body, ProjectApi.getWingsApi);
  return new Promise((resolve, reject) => {
    api({ ...ProjectApi.getWingsApi })
      .then((data) => resolve(data))
      .catch(({ message }) => reject(Toast({ type: "error", message })));
  });
};

// delete project
export const deleteProjectAct = (id) => () => {
  ProjectApi.deleteProject.project_id = id;
  return new Promise((resolve, reject) => {
    api({ ...ProjectApi.deleteProject })
      .then((data) => {
        Toast({ type: "success", message: "Deleted Successfully" });
        resolve(data);
      })
      .catch(({ message }) => reject(Toast({ type: "error", message })));
  });
};

// add project
export const createProjectAct = (body) => () => {
  return new Promise((resolve, reject) => {
    api({ ...ProjectApi.addProjectApi, body })
      .then((data) => {
        resolve(data);
      })
      .catch(({ message }) => reject(Toast({ type: "error", message })));
  });
};

// update project
export const updateProjectAct = (body) => () => {
  return new Promise((resolve, reject) => {
    api({ ...ProjectApi.updateProjectApi, body })
      .then((data) => {
        resolve(data);
      })
      .catch(({ message }) => reject(Toast({ type: "error", message })));
  });
};

// update Project status
export const updateProjectStatus = (id, type) => () => {
  ProjectApi.updateProjectStatusApi.type = type;
  ProjectApi.updateProjectStatusApi.url_id = id;
  return new Promise((resolve, reject) => {
    api({ ...ProjectApi.updateProjectStatusApi })
      .then((data) => {
        Toast({ type: "success", message: data.message });
        resolve(data);
      })
      .catch(({ message }) => reject(Toast({ type: "error", message })));
  });
};

//region dropdown
export const getRegionsAct = () => () => {
  return new Promise((resolve, reject) => {
    api({ ...ProjectApi.getRegionsApi })
      .then((data) => {
        resolve(data);
      })
      .catch(({ message }) => reject(Toast({ type: "error", message })));
  });
};
//region filter dropdown
export const getRegionFilterAct = (type, body) => () => {
  ProjectApi.getRegionsFilterApi.type = type;
  addQuery(body, ProjectApi.getRegionsFilterApi);
  return new Promise((resolve, reject) => {
    api({ ...ProjectApi.getRegionsFilterApi })
      .then((data) => {
        resolve(data);
      })
      .catch(({ message }) => reject(Toast({ type: "error", message })));
  });
};

// check existing phase code
export const checkPhaseCodeAct = (body) => () => {
  return new Promise((resolve, reject) => {
    api({ ...ProjectApi.checkPhaseCode, body })
      .then((data) => {
        resolve(data);
      })
      .catch(({ message }) => reject(Toast({ type: "error", message })));
  });
};

export const { saveProjects, saveProjectDetail, saveInActiveProjects } =
  projectSlice.actions;

export default projectSlice.reducer;
