import { createSlice } from "@reduxjs/toolkit";
import { api } from "service/api";
import { UserMgmtApi } from "service/apiVariables";
import { Toast } from "service/toast";
import { addQuery } from "utils";
import { setLoader } from "./loader";

export const userMgmtSlice = createSlice({
  name: "userMgmt",
  initialState: {
    employees: null,
    isLoading: true,
    userData: {},
    generatedPwd: null,
    projects: [],
    profilePicDetails: "",
  },
  reducers: {
    saveEmployeesData: (state, action) => {
      state.employees = action.payload;
      state.isLoading = false;
    },

    saveUserData: (state, action) => {
      state.userData = action.payload;
      state.isLoading = false;
    },

    saveGeneratedPwd: (state, action) => {
      state.generatedPwd = action.payload;
      state.isLoading = false;
    },

    saveProjects: (state, action) => {
      state.projects = action.payload;
      state.isLoading = false;
    },
    saveProfilePicData: (state, action) => {
      state.profilePicDetails = action.payload;
    },
  },
});

// get all employees
export const getAllEmployeesList = (query) => (dispatch) => {
  addQuery(query, UserMgmtApi.getAllEmployeeApi);
  return new Promise((resolve, reject) => {
    api({ ...UserMgmtApi.getAllEmployeeApi })
      .then(({ data }) => {
        dispatch(saveEmployeesData(data));
        dispatch(setLoader(false));
      })
      .catch((res) => {
        console.log(res, "resdata");
        if (res?.status === 400) {
          dispatch(saveEmployeesData(res?.data));
          dispatch(setLoader(false));
        } else {
          dispatch(setLoader(false));
          reject(Toast({ type: "error", message: res?.message }));
        }
      });
  });
};

// get individual employee detail
export const getIndividualEmpDetails = (id) => (dispatch) => {
  UserMgmtApi.getEmployeeApi.url_id = id;
  return new Promise((resolve, reject) => {
    api({ ...UserMgmtApi.getEmployeeApi })
      .then((data) => {
        resolve(data?.data);
        dispatch(saveUserData(data?.data));
      })
      .catch(({ message }) => reject(Toast({ type: "error", message })));
  });
};

// generate password
export const generatePassword = (id) => (dispatch) => {
  UserMgmtApi.generatePasswordApi.url_id = id;
  return new Promise((resolve, reject) => {
    api({ ...UserMgmtApi.generatePasswordApi })
      .then((data) => {
        Toast({ type: "success", message: data.message });
        dispatch(saveGeneratedPwd(data));
      })
      .catch(({ message }) => reject(Toast({ type: "error", message })));
  });
};

// update user status
export const updateUserStatus = (id, type) => () => {
  UserMgmtApi.updateUserStatusApi.type = type;
  UserMgmtApi.updateUserStatusApi.url_id = id;
  return new Promise((resolve, reject) => {
    api({ ...UserMgmtApi.updateUserStatusApi })
      .then((data) => {
        // Toast({ type: "success", message: data.message });
        resolve(data);
      })
      .catch(({ message }) => reject(Toast({ type: "error", message })));
  });
};

// delete employee
export const deleteEmployee = (id) => () => {
  UserMgmtApi.deleteUserApi.url_id = id;
  return new Promise((resolve, reject) => {
    api({ ...UserMgmtApi.deleteUserApi })
      .then((data) => {
        Toast({ type: "success", message: data.message });
        resolve(data);
      })
      .catch(({ message }) => reject(Toast({ type: "error", message })));
  });
};

export const employeeFileUpload = (body) => () => {
  return new Promise((resolve, reject) => {
    api({ ...UserMgmtApi.employeeUploadFileApi, body })
      .then(({ data, message }) => {
        Toast({ type: "success", message: message });
        resolve(data);
      })
      .catch(({ message }) => reject(Toast({ type: "error", message })));
  });
};

// profile pic upload
export const employeeProfilePicUpload = (body) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api({ ...UserMgmtApi.imageUploadApi, body })
      .then(({ data, message }) => {
        dispatch(saveProfilePicData(data));
      })
      .catch(({ message }) => reject(Toast({ type: "error", message })));
  });
};

// add employee
export const addEmployeeAct = (body) => () => {
  return new Promise((resolve, reject) => {
    api({ ...UserMgmtApi.addEmployeeApi, body })
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// update employee
export const updateEmployeeAct = (body) => () => {
  return new Promise((resolve, reject) => {
    api({ ...UserMgmtApi.updateEmployeeApi, body })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// list projects
export const getProjectsListAct = () => () => {
  return new Promise((resolve, reject) => {
    api({ ...UserMgmtApi.getProjectsListApi })
      .then((data) => {
        resolve(data);
      })
      .catch(({ message }) => reject(Toast({ type: "error", message })));
  });
};

// list assigned projects
export const getAssignedProjectsList = () => () => {
  return new Promise((resolve, reject) => {
    api({ ...UserMgmtApi.getAssignedProjectsApi })
      .then((data) => {
        resolve(data);
      })
      .catch(({ message }) => reject(Toast({ type: "error", message })));
  });
};

export const getReportsAssignedProjectsList = (query) => () => {
  addQuery(query, UserMgmtApi.getReportsAssignedProjectsApi);
  return new Promise((resolve, reject) => {
    api({ ...UserMgmtApi.getReportsAssignedProjectsApi })
      .then((data) => {
        resolve(data);
      })
      .catch(({ message }) => reject(Toast({ type: "error", message })));
  });
};

//Multiple Status Update
export const multipleStatusUpdateAct = (body) => () => {
  return new Promise((resolve, reject) => {
    api({ ...UserMgmtApi.multipleStatusUpdate, body })
      .then(({ data, message }) => {
        Toast({ type: "success", message: message });
        resolve(data);
      })
      .catch(({ message }) => reject(Toast({ type: "error", message })));
  });
};

export const getExistingEmployee = (query) => () => {
  addQuery(query, UserMgmtApi.getExistingEmpApi);
  return new Promise((resolve, reject) => {
    api({ ...UserMgmtApi.getExistingEmpApi })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => reject(Toast({ type: "error", message })));
  });
};

export const {
  saveEmployeesData,
  saveUserData,
  saveGeneratedPwd,
  saveProjects,
  saveProfilePicData,
} = userMgmtSlice.actions;

export default userMgmtSlice.reducer;
