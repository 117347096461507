import React, { useState } from "react";
import PropTypes from "prop-types";
import AdminNavbar from "component/common/Navbar";
import "./mainlayout.scss";
import { Sidebar } from "component/common/Sidebar";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

export function MainLayout(props) {
  const { children } = props;

  const [isOpen, setOpen] = useState(true);

  const toggle = () => {
    setOpen(!isOpen);
  };
  const location = useLocation();

  return (
    <>
      <Sidebar isOpen={isOpen} />

      <div className={`main-layout`}>
        <div className="main-layout-wrapper">
          <AdminNavbar isOpen={isOpen} toggle={toggle} />
          <div
            className={`${
              location.pathname !== "/notification"
                ? "main-content-wrapper"
                : "notification-content-wrapper"
            } ${isOpen ? "open-layout" : "closed-layout"}`}
          >
            {children}
          </div>
        </div>
      </div>
    </>
  );
}

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
