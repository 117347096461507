import React from "react";
import PropTypes from "prop-types";

import "./authlayout.scss";

export function AuthLayout(props) {
  const { children } = props;

  return <div className="auth-content-wrapper">{children}</div>;
}

AuthLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
