import { createSlice } from "@reduxjs/toolkit";
import { addQuery } from "helpers/utis";
import { api } from "service/api";
import { AuthApi } from "service/apiVariables";
import { Toast } from "service/toast";
import { setLoader } from "./loader";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    isRememberMe: false,
    isLoading: true,
    userData: null,
    token: null,
    empCodes: [],
  },
  reducers: {
    saveRememberMe: (state, action) => {
      state.isRememberMe = action.payload;
    },
    saveUserData: (state, action) => {
      state.userData = action.payload;
      state.token = action.payload.token;
      state.isLoading = false;
    },
    logout: (state) => {
      state.userData = null;
      state.isRememberMe = false;
      state.empCodes = [];
    },
  },
});

export const getEmployeeCodes = () => () => {
  return new Promise((resolve, reject) => {
    api({ ...AuthApi.empCodesApi })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => reject(Toast({ type: "error", message })));
  });
};

export const login = (body) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api({ ...AuthApi.loginApi, body })
      .then(({ data, message }) => {
        if (data?.role === 1 || data?.role === 2) {
          Toast({ type: "success", message: message });
          dispatch(saveUserData(data));
          resolve(data);

          localStorage.setItem("userDetails", JSON.stringify(data));
          sessionStorage.setItem("token", data?.token);
        } else {
          Toast({ type: "error", message: "Invalid User" });
        }
      })
      .catch(({ message }) => reject(Toast({ type: "error", message })));
  });
};

export const resetPasswordAct = (body) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api({ ...AuthApi.resetPasswordApi, body })
      .then(({ user }) => {
        Toast({ type: "success", message: "Password changed successfully" });
        resolve(user);
      })
      .catch((error) => {
        reject(error); // Pass the error object to catch block
      });
  });
};

export const forgetPasswordAct = (email) => (dispatch) => {
  AuthApi.forgetPasswordApi.email = email;
  return new Promise((resolve, reject) => {
    api({ ...AuthApi.forgetPasswordApi })
      .then(({ data, message }) => {
        dispatch(setLoader(false));
        Toast({ type: "success", message: message });
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
        dispatch(setLoader(false));
      });
  });
};

export const getTokenValidAct = (query) => () => {
  addQuery(query, AuthApi.getValidToken);
  return new Promise((resolve, reject) => {
    api({ ...AuthApi.getValidToken })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const mailResetPasswordAct = (body) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api({ ...AuthApi.mailResetPasswordApi, body })
      .then(({ data, message }) => {
        Toast({ type: "success", message: message });
        dispatch(saveUserData(data));
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const { saveRememberMe, saveUserData, logout } = authSlice.actions;

export default authSlice.reducer;
