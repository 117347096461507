import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist"; // Import from "redux-persist" instead of "reduxjs-toolkit-persist"
import storage from "redux-persist/lib/storage";
import rootReducer from "./reducers";

const persistConfig = {
  key: "root",
  storage: storage,
};

const _persistedReducer = persistReducer(persistConfig, rootReducer);

const extraArguments = {};

// Configure store
export const store = configureStore({
  reducer: _persistedReducer,
  devTools: true,
  middleware: (getDefaultMiddleware) => {
    const defaultMiddleware = getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          "FLUSH",
          "REHYDRATE",
          "PAUSE",
          "PERSIST",
          "PURGE",
          "REGISTER",
        ],
      },
    });
    return [...defaultMiddleware, thunk.withExtraArgument(extraArguments)];
  },
});

export const persistor = persistStore(store);
