import { createSlice } from "@reduxjs/toolkit";
import { api } from "service/api";
import { Toast } from "service/toast";
import { AttendanceApi } from "service/apiVariables";
import { NO_DATA, addQuery } from "helpers/utis";
import { setLoader } from "./loader";

export const attendanceReport = createSlice({
  name: "attendanceReport",
  initialState: {
    attendanceData: [],
    isLoading: true,
  },
  reducers: {
    saveAttendanceData: (state, action) => {
      state.attendanceData = action.payload;
      state.isLoading = false;
    },
  },
});

export const getAttendance = (query) => (dispatch) => {
  addQuery(query, AttendanceApi.getAttendanceApi);
  return new Promise((resolve, reject) => {
    api({ ...AttendanceApi.getAttendanceApi })
      .then(({ data }) => {
        dispatch(saveAttendanceData(data));
        dispatch(setLoader(false));
        resolve(data);
      })
      .catch(({ message }) => {
        dispatch(setLoader(false));
        if (message === NO_DATA) {
          dispatch(saveAttendanceData([]));
        } else {
          reject(Toast({ type: "error", message }));
        }
      });
  });
};

export const downloadAttendance = (query) => (dispatch) => {
  addQuery(query, AttendanceApi.getAttendanceApi);
  return new Promise((resolve, reject) => {
    api({ ...AttendanceApi.getAttendanceApi })
      .then((data) => {
        resolve(data);
      })
      .catch(({ message }) => {
        if (message === NO_DATA) {
          dispatch(saveAttendanceData([]));
        } else {
          reject(Toast({ type: "error", message }));
        }
      });
  });
};

export const getEmployeeDetail = (query) => () => {
  addQuery(query, AttendanceApi.getEmpDetailApi);
  return new Promise((resolve, reject) => {
    api({ ...AttendanceApi.getEmpDetailApi })
      .then(({ data, message }) => {
        Toast({ type: "success", message: message });
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createAttendance = (body) => () => {
  return new Promise((resolve, reject) => {
    api({ ...AttendanceApi.createAttendanceApi, body })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ message }) => reject(Toast({ type: "error", message })));
  });
};

// upload
export const attendanceFileUpload = (body) => () => {
  return new Promise((resolve, reject) => {
    api({ ...AttendanceApi.attendanceUploadFileApi, body })
      .then((data) => {
        Toast({ type: "success", message: data?.message });
        resolve(data);
      })
      .catch(({ message }) => reject(Toast({ type: "error", message })));
  });
};

export const { saveAttendanceData } = attendanceReport.actions;

export default attendanceReport.reducer;
