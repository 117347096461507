import React from "react";
import "./wordStyles.scss";

export default function TooltipWordComponent({
  content,
  lengthTo,
  isLeft = false,
}) {
  const truncateContent = (text, maxLength) => {
    const words = text.split(" ");
    if (words.length > maxLength) {
      return words.slice(0, maxLength).join(" ") + "...";
    } else {
      return text;
    }
  };

  return (
    <div>
      {content ? (
        <label>
          <div className={isLeft ? "leftWordTooltip" : "wordTooltip"}>
            <div>{truncateContent(content, lengthTo)}</div>
            {content.length > lengthTo && (
              <span
                className={isLeft ? "leftWordTooltiptext" : "wordTooltiptext"}
              >
                {content}
              </span>
            )}
          </div>
        </label>
      ) : (
        "-"
      )}
    </div>
  );
}
