import { createSlice } from "@reduxjs/toolkit";
import { api } from "service/api";
import { ReportMgmtApi, ReportsApi } from "service/apiVariables";
import { Toast } from "service/toast";
import { setLoader } from "./loader";

export const reportsSlice = createSlice({
  name: "reports",
  initialState: {
    isLoading: true,
    reportDetail: null,
    reports: [],
  },
  reducers: {
    saveReports: (state, action) => {
      state.reports = action.payload;
      state.isLoading = false;
    },
    saveReportDetail: (state, action) => {
      state.reportDetail = action.payload;
      state.isLoading = false;
    },
  },
});

// get individual report details
export const getIndividualReportDetails = (id) => (dispatch) => {
  ReportMgmtApi.getReportApi.url_id = id;
  return new Promise((resolve, reject) => {
    api({ ...ReportMgmtApi.getReportApi })
      .then((data) => {
        dispatch(saveReportDetail(data));
        resolve(data);
      })
      .catch(({ message }) => reject(Toast({ type: "error", message })));
  });
};

export const updateReportAct = (body) => () => {
  return new Promise((resolve, reject) => {
    api({ ...ReportMgmtApi.updateReportsApi, body })
      .then((data) => {
        resolve(data);
      })
      .catch(({ message }) => reject(Toast({ type: "error", message })));
  });
};

export const getReportsFilterAct = (body) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api({ ...ReportsApi.getFilterReportApi, body })
      .then(({ data, message }) => {
        dispatch(saveReports(data));
        resolve(data);
        dispatch(setLoader(false));
      })
      .catch(({ message }) => {
        // temp call
        dispatch(setLoader(false));
        dispatch(saveReports([]));
        reject(Toast({ type: "error", message }));
      });
  });
};

// download reports
export const downloadFileAct = (body) => () => {
  return new Promise((resolve, reject) => {
    api({ ...ReportMgmtApi.downloadFileApi, body })
      .then((data) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(Toast({ type: "error", message }));
      });
  });
};

export const { saveReportDetail, saveReports } = reportsSlice.actions;

export default reportsSlice.reducer;
