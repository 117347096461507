import { createSlice } from "@reduxjs/toolkit";
import { api } from "service/api";
import { Toast } from "service/toast";
import { ActivityApi } from "service/apiVariables";
import { NO_DATA, addQuery } from "helpers/utis";
import { setLoader } from "./loader";

export const activitySlice = createSlice({
  name: "activity",
  initialState: {
    activityData: "",
    tradeData: "",
    activityDetail: null,
    isLoading: true,
    uomData: "",
  },
  reducers: {
    saveTradeData: (state, action) => {
      state.tradeData = action.payload;
    },
    saveActivityData: (state, action) => {
      state.activityData = action.payload;
      state.isLoading = false;
    },
    saveActivityDetail: (state, action) => {
      state.activityDetail = action.payload;
      state.isLoading = false;
    },
    saveUomData: (state, action) => {
      state.uomData = action.payload;
      state.isLoading = false;
    },
  },
});

// get all activity
export const getAllActivityAct = (query) => (dispatch) => {
  addQuery(query, ActivityApi.getActivityApi);
  return new Promise((resolve, reject) => {
    api({ ...ActivityApi.getActivityApi })
      .then(({ data, message }) => {
        dispatch(saveActivityData(data));
        dispatch(setLoader(false));
      })
      .catch(({ message }) => {
        dispatch(setLoader(false));
        if (message === NO_DATA) {
          dispatch(saveActivityData([]));
        } else {
          reject(Toast({ type: "error", message }));
        }
      });
  });
};

// delete activity
export const deleteActivityAct = (id) => () => {
  ActivityApi.deleteActivity.activity_master_id = id;
  return new Promise((resolve, reject) => {
    api({ ...ActivityApi.deleteActivity })
      .then((data) => {
        Toast({ type: "success", message: data.message });
        resolve(data);
      })
      .catch(({ message }) => reject(Toast({ type: "error", message })));
  });
};

// get single activity
export const getSingleActivityAct = (id, body) => (dispatch) => {
  ActivityApi.getSingleActivityApi.activity_master_id = id;
  addQuery(body, ActivityApi.getSingleActivityApi);
  return new Promise((resolve, reject) => {
    api({ ...ActivityApi.getSingleActivityApi })
      .then(({ data }) => {
        dispatch(saveActivityDetail(data));
        resolve(data);
      })
      .catch(({ message }) => reject(Toast({ type: "error", message })));
  });
};

// add activity
export const createActivityAct = (body) => () => {
  return new Promise((resolve, reject) => {
    api({ ...ActivityApi.addActivityApi, body })
      .then((data) => {
        resolve(data);
      })
      .catch(({ message }) => reject(Toast({ type: "error", message })));
  });
};

// update activity
export const updateActivityAct = (body) => () => {
  return new Promise((resolve, reject) => {
    api({ ...ActivityApi.updateActivityApi, body })
      .then((data) => {
        resolve(data);
      })
      .catch(({ message }) => reject(Toast({ type: "error", message })));
  });
};

// activity dropdown
export const getActivityDropDownAct = (id) => () => {
  ActivityApi.getActivityDropDown.activity_trade_id = id;
  addQuery(ActivityApi.getActivityDropDown);
  return new Promise((resolve, reject) => {
    api({ ...ActivityApi.getActivityDropDown })
      .then((data) => resolve(data))
      .catch(({ message }) => reject(Toast({ type: "error", message })));
  });
};

export const getTradeDropDownAct = () => (dispatch) => {
  return new Promise((reject) => {
    api({ ...ActivityApi.getTradeDropDown })
      .then((data) => dispatch(saveTradeData(data)))
      .catch(({ message }) => reject(Toast({ type: "error", message })));
  });
};

export const getUomDropDownAct = () => (dispatch) => {
  return new Promise((reject) => {
    api({ ...ActivityApi.getUomDropDown })
      .then((data) => dispatch(saveUomData(data)))
      .catch(({ message }) => reject(Toast({ type: "error", message })));
  });
};

//upload
export const activityFileUpload = (body) => () => {
  return new Promise((resolve, reject) => {
    api({ ...ActivityApi.activityUploadFileApi, body })
      .then((data) => {
        resolve(data);
      })
      .catch(({ message }) => reject(Toast({ type: "error", message })));
  });
};

export const {
  saveTradeData,
  saveActivityData,
  saveActivityDetail,
  saveUomData,
} = activitySlice.actions;

export default activitySlice.reducer;
