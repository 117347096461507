import { Navbar } from "react-bootstrap";
import React, { useState } from "react";
import PropTypes from "prop-types";
import MenuToggle from "assets/images/menu-toggle.svg";
import MenuRightToggle from "assets/images/menuRightIcon.svg";
import eclipse from "assets/images/round.png";
import "./navbar.scss";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { getRoleLabelByValue, history } from "helpers/utis";
import { FiLogOut } from "react-icons/fi";
import { IoIosArrowDown } from "react-icons/io";
import TooltipWordComponent from "component/common/ToolTip/TooltipWord";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "redux/reducers/authSlice";
import ProfileImagePopup from "./ProfileImagePopup";

function AdminNavbar({ isOpen, toggle }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const location = useLocation();
  const { userData } = useSelector((state) => state?.auth);
  const userName = userData?.employee_name || "User";
  const capitalizeFirstLetter = (name) => {
    return name
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const capitalizedUserName = capitalizeFirstLetter(userName);
  const nameParts = capitalizedUserName.split(" ");
  const firstLetter = userName?.charAt(0)?.toUpperCase();
  const role = userData?.role;

  const dispatch = useDispatch();

  const getTitle = () => {
    const { pathname } = location;
    if (pathname.startsWith("/dashboard")) {
      return "Dashboard";
    } else if (pathname.startsWith("/reports")) {
      return "Reports";
    } else if (pathname.startsWith("/projects")) {
      return "Projects";
    } else if (pathname.startsWith("/users")) {
      return "User Management";
    } else if (pathname.startsWith("/attendanceReports")) {
      return "Attendance Report";
    } else if (pathname === "/about") {
      return "About";
    } else if (pathname === "/termsPolicies") {
      return "Terms & Policies";
    } else if (pathname === "/settings") {
      return "Settings";
    } else if (pathname.startsWith("/activities")) {
      return "Activities Management";
    } else {
      return " ";
    }
  };

  const handleLogout = () => {
    dispatch(logout());
    sessionStorage.removeItem("token");
    localStorage.removeItem("token");
    history.push("/");
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleOpenProfile = () => {
    setOpenProfile(true);
  };

  return (
    <div className="navbar-container p-0">
      <Navbar
        expand="lg"
        variant="light"
        bg="white"
        fixed="top"
        className={`admin-navbar ${isOpen ? "open-sidebar" : "closed-sidebar"}`}
      >
        <div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <div
                className="cursor-pointer position-relative"
                onClick={toggle}
              >
                <img src={eclipse} alt="eclipse" className="eclipseWidth" />
                {isOpen ? (
                  <img src={MenuToggle} alt="menu-toggle" className="menu" />
                ) : (
                  <img
                    src={MenuRightToggle}
                    alt="menu-toggle"
                    className="menu"
                  />
                )}
              </div>
              <div>
                <h3 className="fs-30 fw-500 title-black ml-2 my-0 style">
                  {getTitle()}
                </h3>
              </div>
            </div>

            <div className="d-flex align-items-center mt-2">
              {userData?.signed_url ? (
                <img
                  src={userData?.signed_url}
                  alt=""
                  className="navProfileImg mr-4"
                  onClick={handleOpenProfile}
                />
              ) : (
                <div className="user-initial-circle mr-4">{firstLetter}</div>
              )}
              <div className="user-dropdown mr-4">
                <div>
                  <div className="d-flex">
                    Hi ,
                    {nameParts?.length > 1 ? (
                      <TooltipWordComponent
                        content={capitalizedUserName}
                        lengthTo={1}
                        isLeft={true}
                      />
                    ) : (
                      <div className="ml-1">{capitalizedUserName}</div>
                    )}
                  </div>
                  <div className="roleLabel">{getRoleLabelByValue(role)}</div>
                </div>
                <IoIosArrowDown onClick={toggleDropdown} className="ml-2" />
                {dropdownOpen && (
                  <div className="dropdown-content">
                    <div className="dropdown-item" onClick={handleLogout}>
                      <FiLogOut className="mr-1" /> Logout
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Navbar>
      <ProfileImagePopup
        showModal={openProfile}
        closeModal={() => setOpenProfile(false)}
        data={userData}
      />
    </div>
  );
}

AdminNavbar.propTypes = {
  isOpen: PropTypes.bool,
};

AdminNavbar.defaultProps = {
  isOpen: true,
};

export default AdminNavbar;
