import React from "react";
import ReactDOM from "react-dom";
import Routes from "./routes/index";
import { Provider } from "react-redux";
import "./assets/scss/main.scss";
import "react-notifications/lib/notifications.css";

// toolkit store
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux/store";

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Routes />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
