import { createSlice } from "@reduxjs/toolkit";
import { api } from "service/api";
import { Toast } from "service/toast";
import { DashboardApi } from "service/apiVariables";
import { setLoader } from "./loader";

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    projectCount: {},
    filterList: {},
  },
  reducers: {
    saveProjectsCount: (state, action) => {
      state.projectCount = action.payload;
    },
    saveFilterList: (state, action) => {
      state.filterList = action.payload;
    },
  },
});

export const getTotalProjectsAct = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    api({ ...DashboardApi.getTotalProjects })
      .then((data) => {
        dispatch(saveProjectsCount(data?.data));
        resolve(data);
      })
      .catch(({ message }) => reject(Toast({ type: "error", message })));
  });
};

export const getDashboardFilterAct = (body) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api({ ...DashboardApi.getDashboardFilterList, body })
      .then(({ data }) => {
        dispatch(setLoader(false));
        dispatch(saveFilterList(data));
        resolve(data);
      })
      .catch(({ message }) => {
        dispatch(setLoader(false));
        dispatch(saveFilterList({}));
        reject(Toast({ type: "error", message }));
      });
  });
};

export const { saveProjectsCount, saveFilterList } = dashboardSlice.actions;

export default dashboardSlice.reducer;
