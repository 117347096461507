import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Logo from "assets/images/logo.png";
import "./sidebar.scss";
import { menuItems, menuItemsCosting } from "Data/staticDatas";
import { ListItem } from "./ListItem";
import { useSelector } from "react-redux";
export function Sidebar({ isOpen }) {
  const { userData } = useSelector((state) => state?.auth);
  let userDetail = [];
  if (userData?.role === 1 || userData?.role === 8) {
    userDetail = menuItems;
  } else if (userData?.role === 2) {
    userDetail = menuItemsCosting;
  }

  return (
    <div
      className={`custom-sidebar ${isOpen ? "open-sidebar" : "closed-sidebar"}`}
    >
      <div className="menu-container">
        {isOpen ? (
          <Link to={userDetail[0]?.link} className="sidebar-logo">
            <img src={Logo} alt="Brand Logo" />
          </Link>
        ) : (
          <Link to={userDetail[0]?.link} className="sidebar-logo"></Link>
        )}
      </div>
      <div className="menu-sidebar py-md-4 py-3 px-1">
        <ul className="items-container">
          {userDetail?.map((data) => (
            <>
              <ListItem {...data} key={data.module} isOpen={isOpen} />
            </>
          ))}
        </ul>
        {isOpen && (
          <div className="sidebar-footer text-center">
            <div className="text-lightgrey">Copyright 2024 SOBHA</div>
            <div className="mb-2">v 1.0.0</div>
          </div>
        )}
      </div>
    </div>
  );
}

Sidebar.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
};

Sidebar.defaultProps = {
  toggle: () => null,
  isOpen: true,
};
