import React from "react";
import Modal from "react-bootstrap/Modal";
import "./styles.scss";
const Popup = (props) => {
  const {
    children,
    setPopup,
    isOpen,
    label = "",
    className = "",
    size = "",
    closeButton = false,
  } = props;

  return (
    <Modal
      show={isOpen}
      onHide={setPopup}
      size={size}
      centered={true}
      className={`modal-bottom ${className}`}
      backdrop="static"
    >
      {(label || closeButton) && (
        <Modal.Header
          closeButton={closeButton}
          className={
            label
              ? "border-bottom-0 p-4 pe-5 fs-26 fw-700 text-center"
              : "border-bottom-0 p-0"
          }
        >
          {label}
        </Modal.Header>
      )}
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};

export default Popup;
