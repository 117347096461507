const routers = [
  {
    path: "/",
    redirect: "/auth",
    auth: false,
  },
  // About for mobile
  {
    component: "AboutMobile",
    path: "/aboutPage",
    componentPath: "pages/About",
    name: "AboutMobile",
    auth: false,
    exact: true,
  },
  // Policies for mobile

  {
    component: "Policies",
    path: "/policies",
    componentPath: "pages/Policy",
    name: "Policies",
    auth: false,
    exact: true,
  },
  // Terms for mobile

  {
    component: "Terms",
    path: "/terms",
    componentPath: "pages/Terms",
    name: "Terms",
    auth: false,
    exact: true,
  },
  {
    component: "AuthLayout",
    path: "/auth",
    auth: false,
    name: "Auth",
    exact: false,
    redirect: "/auth/login",
    childrens: [
      {
        component: "Login",
        path: "/login",
        componentPath: "pages/Auth/Login",
        name: "Login",
        auth: false,
        exact: true,
      },
      {
        component: "Reset",
        path: "/reset",
        componentPath: "pages/Auth/Reset",
        name: "Reset",
        auth: false,
        exact: true,
      },
      {
        component: "MailReset",
        path: "/mail-reset",
        componentPath: "pages/Auth/MailReset",
        name: "MailReset",
        auth: false,
        exact: true,
      },
    ],
  },
  {
    component: "MainLayout",
    path: "/dashboard",
    auth: false,
    name: "MainLayout",
    exact: false,
    childrens: [
      {
        component: "Dashboard",
        path: "/",
        componentPath: "pages/Dashboard",
        name: "Dashboard",
        auth: false,
        exact: true,
      },
      {
        component: "ProductiveWorks",
        path: "/productiveWorks",
        componentPath: "pages/ProductiveWorks",
        name: "ProductiveWorks",
        auth: false,
        exact: true,
      },
      {
        component: "NonProductive",
        path: "/nonProductive",
        componentPath: "pages/NonProductive",
        name: "NonProductive",
        auth: false,
        exact: true,
      },
    ],
  },
  //Project
  {
    component: "MainLayout",
    path: "/projects",
    auth: false,
    name: "MainLayout",
    exact: false,
    childrens: [
      {
        component: "Projects",
        path: "/",
        componentPath: "pages/Project",
        name: "Projects",
        auth: false,
        exact: true,
      },
      {
        component: "ProjectDetail",
        path: "/view/:project_id",
        componentPath: "pages/ProjectDetails",
        name: "ProjectDetail",
        auth: false,
        exact: true,
      },
      {
        component: "AddProjects",
        path: "/add",
        componentPath: "pages/AddProject",
        name: "AddProjects",
        auth: false,
        exact: true,
      },
      {
        component: "AddProjects",
        path: "/edit/:project_id",
        componentPath: "pages/AddProjects",
        name: "AddProjects",
        auth: false,
        exact: true,
      },
      {
        component: "EditProjects",
        path: "/edit",
        componentPath: "pages/EditProjects",
        name: "EditProjects",
        auth: false,
        exact: true,
      },
      {
        component: "UploadProject",
        path: "/upload",
        componentPath: "pages/UploadProject",
        name: "UploadProject",
        auth: false,
        exact: true,
      },
    ],
  },
  //User Management
  {
    component: "MainLayout",
    path: "/users",
    auth: false,
    name: "MainLayout",
    exact: false,
    childrens: [
      {
        component: "Users",
        path: "/",
        componentPath: "pages/User-management",
        name: "User",
        auth: false,
        exact: true,
      },
      {
        component: "AddUsers",
        path: "/add",
        componentPath: "pages/AddUserManagement",
        name: "AddUsers",
        auth: false,
        exact: true,
      },
      {
        component: "EditUsers",
        path: "/edit",
        componentPath: "pages/EditUserManagement",
        name: "EditUsers",
        auth: false,
        exact: true,
      },
      {
        component: "UserDetail",
        path: "/view",
        componentPath: "pages/UserDetails",
        name: "UserDetail",
        auth: false,
        exact: true,
      },
      {
        component: "UploadUsers",
        path: "/upload",
        componentPath: "pages/UploadUser",
        name: "UploadUsers",
        auth: false,
        exact: true,
      },
    ],
  },
  //Activities
  {
    component: "MainLayout",
    path: "/activities",
    auth: false,
    name: "MainLayout",
    exact: false,
    childrens: [
      {
        component: "Activities",
        path: "/",
        componentPath: "pages/Activities",
        name: "Activities",
        auth: false,
        exact: true,
      },
      {
        component: "ActivityDetails",
        path: "/view/:activity_master_id",
        componentPath: "pages/ActivityDetail",
        name: "ActivityDetails",
        auth: false,
        exact: true,
      },
      {
        component: "UploadActivities",
        path: "/upload",
        componentPath: "pages/UploadActivities",
        name: "UploadActivities",
        auth: false,
        exact: true,
      },
      {
        component: "AddActivities",
        path: "/add",
        componentPath: "pages/AddActivity",
        name: "AddActivities",
        auth: false,
        exact: true,
      },
      {
        component: "EditActivities",
        path: "/edit/:activity_master_id",
        componentPath: "pages/EditActivity",
        name: "EditActivities",
        auth: false,
        exact: true,
      },
    ],
  },
  //Reports
  {
    component: "MainLayout",
    path: "/reports",
    auth: false,
    name: "MainLayout",
    exact: false,
    childrens: [
      {
        component: "Reports",
        path: "/",
        componentPath: "pages/Reports",
        name: "Reports",
        auth: false,
        exact: true,
      },
      {
        component: "ReportDetails",
        path: "/view",
        componentPath: "pages/ReportDetail",
        name: "ReportDetails",
        auth: false,
        exact: true,
      },
      {
        component: "EditReports",
        path: "/edit",
        componentPath: "pages/EditReport",
        name: "EditReports",
        auth: false,
        exact: true,
      },
    ],
  },
  //Attendance Report
  {
    component: "MainLayout",
    path: "/attendanceReports",
    auth: false,
    name: "MainLayout",
    exact: false,
    childrens: [
      {
        component: "Attendance",
        path: "/",
        componentPath: "pages/AttendanceReport",
        name: "Attendance",
        auth: false,
        exact: true,
      },
      {
        component: "AddAttendance",
        path: "/add",
        componentPath: "pages/AddAttendances",
        name: "AddAttendance",
        auth: false,
        exact: true,
      },
      {
        component: "UploadAttendance",
        path: "/upload",
        componentPath: "pages/UploadAttendances",
        name: "UploadAttendance",
        auth: false,
        exact: true,
      },
    ],
  },
  //About
  {
    component: "MainLayout",
    path: "/about",
    auth: false,
    name: "MainLayout",
    exact: false,
    childrens: [
      {
        component: "About",
        path: "/",
        componentPath: "pages/Aboutus",
        name: "About",
        auth: false,
        exact: true,
      },
    ],
  },
  //Terms and Policies
  {
    component: "MainLayout",
    path: "/termsPolicies",
    auth: false,
    name: "MainLayout",
    exact: false,
    childrens: [
      {
        component: "TermsPolicies",
        path: "/",
        componentPath: "pages/TermsPolicies",
        name: "TermsPolicies",
        auth: false,
        exact: true,
      },
    ],
  },
  //Settings
  {
    component: "MainLayout",
    path: "/settings",
    auth: false,
    name: "MainLayout",
    exact: false,
    childrens: [
      {
        component: "Settings",
        path: "/",
        componentPath: "pages/Settings",
        name: "Settings",
        auth: false,
        exact: true,
      },
    ],
  },
];

export default routers;
