import moment from "moment";

import { Toast } from "service/toast";
import axios from "axios";
import { history } from "helpers/utis";

export const dateFormatter = (date, format = "DD/MM/YYYY") => {
  if (!date) return "";

  const finalDate = moment(date).format(format);
  return finalDate === "Invalid date" ? "" : finalDate;
};

/**
 * Extract the right error message from react-hook-form error object
 *
 * @param {object} errors Errors object from react-hook-form
 * @param {object} fieldArrayError  { index, fieldName: "date", arrayName: "callLogs", }
 * @param {string} name
 */

export const getFormErrorMessage = (errors, fieldArrayError, name) => {
  if (!name || !errors) return null;

  if (!fieldArrayError && name) {
    return errors[name];
  }

  return (
    !!errors[fieldArrayError.arrayName] &&
    errors[fieldArrayError.arrayName][fieldArrayError.index] &&
    typeof errors[fieldArrayError.arrayName][fieldArrayError.index][
      fieldArrayError.fieldName
    ] !== "undefined" &&
    errors[fieldArrayError.arrayName][fieldArrayError.index][
      fieldArrayError.fieldName
    ]
  );
};

export const urlCombiner = (url) => {
  const protocol = ["h", "t", "t", "p"].join("");
  return `${protocol}://${url}`;
};

export const defaultValidator = (val, defaultValue) => {
  return val || defaultValue;
};

export const ToastError = (err) => {
  return Toast({ type: "error", message: err.Error || err.message || "Error" });
};

// add Query
export const addQuery = (dataObject, apiObject) => {
  if (!dataObject) {
    return "";
  }

  const keys = [
    "page",
    "search",
    "size",
    "trimRequestIds",
    "costingSheetId",
    "fabricRequestId",
    "fabricDetailsId",
    "fabricPOId",
    "trimPOId",
    "trimDetailsId",
    "fileName",
    "dateModified",
    "url_id",
    "status",
    "startDate",
    "endDate",
    "unitId",
    "lineId",
    "mainList",
    "filterStatus",
    "unOrderedList",
    "type",
    "detailsId",
    "reqId",
    "fabricRequestIds",
    "filterForGoods",
    "limit",
    "color",
    "currentDate",
    "role",
    "sort_by",
    "order_by",
    "employee_id",
    "slug",
    "type",
  ];

  keys.forEach((key) => {
    if (dataObject.hasOwnProperty(key) && typeof dataObject[key] != "object") {
      if (apiObject.query.hasOwnProperty(key)) {
        apiObject.addQuery = { key, payload: dataObject[key] };
      }
    } else {
      dataObject[key] &&
        Object.keys(dataObject[key]).forEach((keyName) => {
          if (apiObject.query.hasOwnProperty(keyName)) {
            apiObject.addQuery = {
              key: keyName,
              payload: dataObject[key][keyName],
            };
          }
        });
    }
  });
};

// generate Query
export const generateQuery = (query) => {
  let url = "";
  if (query.hasOwnProperty("url_id")) {
    url = `/${query?.url_id}`;
  }

  return (
    url +
    Object.keys(query).reduce((accumulator, key, index) => {
      if (
        query[key] === "" ||
        query[key] == null ||
        key === "url_id" ||
        (query[key] !== null && query[key].toString().trim() === "")
      ) {
        return accumulator;
      } else {
        return accumulator + `${index !== 0 ? "&" : "?"}${key}=${query[key]}`;
      }
    }, "")
  );
};

export const getToken = () => {
  if (typeof window !== "undefined") {
    return sessionStorage.getItem("token") || localStorage.getItem("token");
  }
};

export const ternaryCondition = (val1, val2 = "", defaultValue = "") => {
  return val1 ? val2 : defaultValue;
};

//get s.no
export const getSNo = (index, page = 1) => {
  return parseInt((page - 1) * 10) + index + 1;
};

export const getIpAddress = async () => {
  const data = await axios({ method: "get", url: "https://api.ipify.org" });
  let ip = data?.data;
  localStorage.setItem("ip", ip);
};

export const getIp = () => {
  return defaultValidator(localStorage.getItem("ip"), "");
};

export const goBack = () => {
  history.goBack();
};

export const getRoleId = () => {
  let user = JSON.parse(localStorage.getItem("userDetails"));
  return user.role;
};

export const showHyphen = (data) => data ?? "-";

// merge the employee code and id
export const mergeCodenId = (empCode, empId) => `${empCode}-${empId}`;

export const Roles = [
  {
    label: "Admin",
    value: 8,
  },
  {
    label: "Foreman",
    value: 6,
  },
  {
    label: "Supervisor",
    value: 5,
  },
  {
    label: "Project Head",
    value: 3,
  },
  {
    label: "Cost Auditor",
    value: 4,
  },
  { label: "Technician", value: 7 },
  {
    label: "Super Admin",
    value: 1,
  },
];

export const Grades = [
  {
    label: "A",
    value: 1,
  },
  {
    label: "AA",
    value: 2,
  },
  {
    label: "AAA",
    value: 3,
  },
];

// department
export const Trades = [
  {
    label: "Tiles",
    value: 1,
  },
  {
    label: "Electrical",

    value: 2,
  },
  {
    label: "Painting",
    value: 3,
  },
  {
    label: "Carpenter",
    value: 4,
  },
  {
    label: "Interior",
    value: 5,
  },
  {
    label: "Granites",
    value: 6,
  },
];

export const getRoleLabelByValue = (value) => {
  const role = Roles.find((role) => role.value === value);
  return role ? role.label : null;
};

export const getGradeLabelByValue = (value) => {
  const grade = Grades.find((data) => data.value === value);
  return grade ? grade.label : null;
};

export const getTradeLabelByValue = (value) => {
  const trade = Trades.find((data) => data.value === value);
  return trade ? trade.label : null;
};

// split the empcode and name
export const splitAndFormat = (input) => {
  // Split the input string on the hyphen
  let parts = input.split("-");

  // The first part is the text which needs to be capitalized properly
  let textPart = parts[0].charAt(0) + parts[0].slice(1).toLowerCase();

  // The second part is the numeric string, assume it's already in the correct format
  let numberPart = parts[1];

  return [textPart, numberPart];
};

export const projectNamesComma = (data) => {
  return data
    ?.map((d, index, array) => {
      // Check if the current item is the last in the array
      if (index === array.length - 1) {
        // No comma for the last item
        return d?.project_name;
      } else {
        // Add a comma after each item, except the last one
        return `${d?.project_name}, `;
      }
    })
    .join(""); // Use join('')
};
