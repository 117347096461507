import Popup from "component/common/Popup";
import { getRoleLabelByValue } from "helpers/utis";
import React from "react";
import "./navbar.scss";
const ProfileImagePopup = ({ closeModal, showModal, data }) => {
  return (
    <Popup isOpen={showModal} setPopup={closeModal} closeButton={true}>
      <div className="profilePopWrap m-0">
        <div className="mb-4 text-center">
          <div className="profileEmpName">{data?.employee_name}</div>
          <div className="profileRoleName">
            {getRoleLabelByValue(data?.role)}
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-center pb-5">
          <img
            src={data?.signed_url}
            alt="img"
            className="modal-profile-image"
          />
        </div>
      </div>
    </Popup>
  );
};

export default ProfileImagePopup;
