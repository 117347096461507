import { generateQuery } from "helpers/utis";
export const AuthApi = {
  empCodesApi: {
    api: "/auth/get-company",
    baseURL: "user",
    method: "get",
  },
  loginApi: {
    api: "/auth/login",
    baseURL: "user",
    method: "post",
  },
  resetPasswordApi: {
    api: "/employee/reset-password",
    baseURL: "user",
    method: "post",
  },
  mailResetPasswordApi: {
    api: "/employee/forgot-password-admin",
    baseURL: "user",
    method: "put",
  },
  forgetPasswordApi: {
    url: "/auth/change-password",
    baseURL: "user",
    method: "post",
    email: "",
    get api() {
      return this.url + "/" + this.email;
    },
  },
  getValidToken: {
    url: "/auth/check-link",
    baseURL: "user",
    method: "get",
    query: {
      slug: "",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
};

export const UserMgmtApi = {
  getAllEmployeeApi: {
    url: "/employee/get-all-employee",
    baseURL: "user",
    query: {
      page: 1,
      limit: 10,
      type: "",
      search: "",
      sort_by: "",
      order_by: "",
      role: "",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getEmployeeApi: {
    url: "/employee/get-employee",
    baseURL: "user",
    url_id: "",
    get api() {
      return this.url + "/" + this.url_id;
    },
    method: "get",
  },
  addEmployeeApi: {
    api: "/employee/add-employee",
    baseURL: "user",
    method: "post",
  },
  updateEmployeeApi: {
    api: "/employee/edit-employee",
    baseURL: "user",
    method: "put",
  },
  multipleStatusUpdate: {
    api: "/employee/multiple-status-update",
    baseURL: "user",
    method: "put",
  },
  generatePasswordApi: {
    url: "/employee/generatePassword",
    baseURL: "user",
    url_id: "",
    get api() {
      return this.url + "/" + this.url_id;
    },
    method: "get",
  },

  deleteUserApi: {
    url: "/employee/del-employee",
    baseURL: "user",
    url_id: "",
    get api() {
      return this.url + "/" + this.url_id;
    },
    method: "delete",
  },
  updateUserStatusApi: {
    url: "/employee/inactive-employee",
    baseURL: "user",
    type: "",
    url_id: "",
    get api() {
      return this.url + "/" + this.type + "/" + this.url_id;
    },
    method: "put",
  },
  getExistingEmpApi: {
    url: "/employee/valid-employee",
    baseURL: "user",
    method: "get",
    query: {
      employee_id: "",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getProjectsListApi: {
    url: "/project/all-projects",
    baseURL: "user",
    method: "get",
    query: {
      page: 1,
      size: 10,
      type: "active",
      search: "",
      sort_by: "",
      order_by: "",
      role: "",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
  },

  getAssignedProjectsApi: {
    api: "/project/all-projects-options",
    baseURL: "user",
    method: "get",
  },

  getReportsAssignedProjectsApi: {
    url: "/project/all-projects-options",
    baseURL: "user",
    method: "get",
    query: {
      type: "",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  employeeUploadFileApi: {
    api: "/employee/employee-upload",
    baseURL: "user",
    method: "post",
  },
  imageUploadApi: {
    api: "/upload/image",
    baseURL: "user",
    method: "post",
  },
};
export const ProjectApi = {
  getAllProjectApi: {
    url: "/project/all-projects",
    baseURL: "user",
    query: {
      page: 1,
      limit: 10,
      type: "active",
      search: "",
      sort_by: "",
      order_by: "",
      region: "",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getInactiveProjectApi: {
    url: "/project/all-projects",
    baseURL: "user",
    query: {
      page: 1,
      limit: 10,
      type: "inactive",
      search: "",
      sort_by: "",
      order_by: "",
      region: "",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  updateProjectStatusApi: {
    url: "/project/project-status",
    baseURL: "user",
    type: "",
    url_id: "",
    get api() {
      return this.url + "/" + this.type + "/" + this.url_id;
    },
    method: "put",
  },
  addProjectApi: {
    api: "/project/add-project",
    baseURL: "user",
    method: "post",
  },
  getSingleProjectApi: {
    url: "/project/project-details/",
    baseURL: "user",
    method: "get",
    project_id: null,
    get api() {
      return this.url + this.project_id;
    },
  },
  getWingsApi: {
    url: "/employee/option-list/",
    baseURL: "user",
    method: "get",
    type: null,
    get api() {
      return this.url + this.type;
    },
  },
  updateProjectApi: {
    api: "/project/edit-project",
    baseURL: "user",
    method: "put",
  },

  deleteProject: {
    url: "/project/delete-project/",
    baseURL: "user",
    method: "delete",
    project_id: null,
    get api() {
      return this.url + this.project_id;
    },
  },
  getRegionsApi: {
    api: "/master/get-all-master-region",
    baseURL: "user",
    method: "get",
  },
  getRegionsFilterApi: {
    url: "/master/get-region/",
    baseURL: "user",
    method: "get",
    type: null,
    get api() {
      return this.url + this.type;
    },
  },
  checkPhaseCode: {
    api: "/project/phase-code-exist",
    baseURL: "user",
    method: "post",
  },
};

export const AboutApi = {
  getAboutApi: {
    api: "/auth/get-about",
    baseURL: "user",
    method: "get",
  },
  getTermsApi: {
    api: "/auth/get-terms/2",
    baseURL: "user",
    method: "get",
  },
  getPoliciesApi: {
    api: "/auth/get-terms/3",
    baseURL: "user",
    method: "get",
  },
};

// Activity Module
export const ActivityApi = {
  getActivityApi: {
    url: "/activity/get-all-admin-actitvity",
    baseURL: "user",
    query: {
      page: 1,
      limit: "",
      type: "",
      search: "",
      sort_by: "",
      order_by: "",
      trade: "",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },

  getSingleActivityApi: {
    url: "/activity/get-admin-actitvity/",
    baseURL: "user",
    method: "get",
    activity_master_id: null,
    get api() {
      return this.url + this.activity_master_id;
    },
  },

  deleteActivity: {
    url: "/activity/delete-admin-activity/",
    baseURL: "user",
    method: "delete",
    activity_master_id: null,
    get api() {
      return this.url + this.activity_master_id;
    },
  },
  addActivityApi: {
    api: "/activity/create-activity",
    baseURL: "user",
    method: "post",
  },
  updateActivityApi: {
    api: "/activity/edit-admin-activity",
    baseURL: "user",
    method: "put",
  },
  getTradeDropDown: {
    api: "/trade/get-all-trades",
    baseURL: "user",
    method: "get",
  },
  getUomDropDown: {
    api: "/activity/getUnitsofMeasurements",
    baseURL: "user",
    method: "get",
  },
  getActivityDropDown: {
    url: "/activity/get-all-master-activity/",
    baseURL: "user",
    method: "get",
    activity_trade_id: null,
    get api() {
      return this.url + this.activity_trade_id;
    },
  },

  activityUploadFileApi: {
    api: "/activity/bulk-upload-activity",
    baseURL: "user",
    method: "post",
  },
};

// Reports Module
export const ReportsApi = {
  getReportsApi: {
    url: "/report/get-all-report",
    baseURL: "user",
    method: "get",
    query: {
      page: 1,
      size: 10,
      type: "",
      search: "",
      sort_by: "",
      order_by: "",
      wings: "",
      projects: [],
      start_date: "",
      end_date: "",
      trade: "",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getFilterReportApi: {
    api: "/report/get-all-report",
    baseURL: "user",
    method: "post",
  },
};

// Report Module
export const ReportMgmtApi = {
  getReportApi: {
    url: "/report/get-report-details",
    baseURL: "user",
    url_id: "",
    get api() {
      return this.url + "/" + this.url_id;
    },
    method: "get",
  },
  updateReportsApi: {
    api: "/report/edit-report",
    baseURL: "user",
    method: "put",
  },
  downloadFileApi: {
    api: "/report/get-download-report",
    baseURL: "user",
    method: "post",
  },
};

export const AttendanceApi = {
  getAttendanceApi: {
    url: "/attendance/get-attendance",
    baseURL: "user",
    method: "get",
    query: {
      page: 1,
      limit: 10,
      date: "",
      trade: "",
      project_id: "",
      sort_by: "",
      order_by: "",
      isExport: "",
      type: "",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  getEmpDetailApi: {
    url: "/attendance/get-emp-det-erp",
    baseURL: "user",
    method: "post",
    query: {
      code: "",
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  createAttendanceApi: {
    api: "/attendance/create-attendance",
    baseURL: "user",
    method: "post",
  },
  attendanceUploadFileApi: {
    api: "/attendance/bulk-attendance-upload",
    baseURL: "user",
    method: "post",
  },
};

export const DashboardApi = {
  getTotalProjects: {
    api: "/dashboard/count",
    baseURL: "user",
    method: "get",
  },
  getDashboardFilterList: {
    api: "/dashboard/filter-count",
    baseURL: "user",
    method: "post",
  },
};
