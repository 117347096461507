/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { ternaryCondition, history } from "helpers/utis";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
export function ListItem({ icon, activeIcon, module, link, isOpen }) {
  const [isActiveItem, setIsActiveItem] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setIsActiveItem(location.pathname.startsWith(link));
  }, [location.pathname]);

  const handleClick = (routeLink) => {
    setIsActiveItem(location.pathname.startsWith(link));
    return history.push(routeLink);
  };

  return (
    <li
      className={`mb-1 cursor-pointer ${ternaryCondition(
        isActiveItem,
        "active-item",
        ""
      )}`}
      title={!isOpen ? module : ""}
    >
      <div
        onClick={() => handleClick(link)}
        className={`${
          isOpen ? "sidebar-container d-flex p-3" : "closedImgIcon p-3"
        }`}
      >
        <img src={isActiveItem ? activeIcon : icon} alt="sidebar-icon" />
        {isOpen && (
          <p className={`${ternaryCondition(isActiveItem, "active-item", "")}`}>
            {module}
          </p>
        )}
      </div>
    </li>
  );
}

ListItem.propTypes = {
  icon: PropTypes.string,
  activeIcon: PropTypes.string,
  module: PropTypes.string,
  link: PropTypes.string,
  isOpen: PropTypes.bool,
};

ListItem.defaultProps = {
  icon: "",
  activeIcon: "",
  module: "",
  link: "",
  isOpen: true,
};
